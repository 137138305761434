@import '../Assets/mixins';

/* general css | large devices */

html {
  -webkit-text-size-adjust: 100%;
}

body {
  @include primeFont();
  color: $primary-color;
  background-color: $primary-background-color;
  margin: 0;
}

/* Grid-View */
* {
  box-sizing: border-box;
}
.row::after {
  content: "";
  clear: both;
  display: table;
}

[class*="col-"] {
  float: left;
  padding: 15px;
}

.col-1 {width: 8.33%;}
.col-2 {width: 16.66%;}
.col-3 {width: 25%;}
.col-4 {width: 33.33%;}
.col-5 {width: 41.66%;}
.col-6 {width: 50%;}
.col-7 {width: 58.33%;}
.col-8 {width: 66.66%;}
.col-9 {width: 75%;}
.col-10 {width: 83.33%;}
.col-11 {width: 91.66%;}
.col-12 {width: 100%;}

/* Flexbox Layout to distribute the content */
.flex {
  display: flex;
  margin: auto;
}

.flex-col{
  display: flex;
  margin: auto;
  flex-direction: column;
}

.flex-container {
  display: flex;
  justify-content:space-around;
  margin: auto;
}

.container {
  display: flex;
  justify-content: center;
  margin: auto;
}

.card-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
}
/* border all */
.border{
  border: 1px solid #000;
}

/* margin all */
.margin{
  margin: 20px;
}

/* margin auto */
.margin-auto{
  margin: auto;
}

/* margin top of 2% */
.mt-1{
  margin-top: 2%;
}

/* margin left of 2% */
.ml-1{
  margin-left: 2%;
}

/* margin right of 2% */
.mr-1{
  margin-right: 2%;
}

/* margin bottom of 2% */
.mb-1{
  margin-bottom: 2%;
}

/* margin top of 5% */
.mt-2{
  margin-top: 5%;
}

/* margin right of 5% */
.mr-2{
  margin-right: 5%;
}

/* margin bottom of 5% */
.mb-2{
  margin-bottom: 5%;
}

/* padding all of 5%*/
.padding-1{
  padding: 5%;
}

/* padding top of 2% */
.pt-1{
  padding-top: 2%;
}

/* padding left of 2% */
.pl-1{
  padding-left: 2%;
}

/* padding right of 2% */
.pr-1{
  padding-right: 2%;
}

/* padding bottom of 2% */
.pb-1{
  padding-bottom: 2%;
}

/* Float Property to letf */
.float-left{
  float: left;
}

/* Float Property to right */
.float-right{
  float: right;
}

/* Text Alignment to the left */
.text-left{
  text-align: left;
}

/* Text Alignment to centre */
.text-center{
  text-align: center;
}

/* Text Alignment to the right */
.text-right{
  text-align: right;
}

/* Text Alignment justify */
.text-justify{
  text-align: justify;
}

/* Image responsive*/
.img {
  width: 100%;
  height: auto;
}

/* Headings*/
h1 {
  @include secondaryFont();
  font-weight: 800;
  font-size: 2rem;
  text-align: center;
}

h2{
  @include secondaryFont();
  text-align: center;
  font-weight: 400;
  font-size: 2.5rem;
}

h3{
  @include secondaryFont();
  font-weight: 400;
  font-size: 2.3rem;
}

p{
  @include primeFont();
  color: $primary-color;
  font-weight: 300;
  font-size: 1.1rem;
  line-height: 1.5;
}

/*= GENERAL CSS | SMALL/MOBILE
--------------------------------------------------------*/
@media screen and (max-width: 1023px) {
  /* For mobiles: */
  .col-s-1 {width: 8.33%;}
  .col-s-2 {width: 16.66%;}
  .col-s-3 {width: 25%;}
  .col-s-4 {width: 33.33%;}
  .col-s-5 {width: 41.66%;}
  .col-s-6 {width: 50%;}
  .col-s-7 {width: 58.33%;}
  .col-s-8 {width: 66.66%;}
  .col-s-9 {width: 75%;}
  .col-s-10 {width: 83.33%;}
  .col-s-11 {width: 91.66%;}
  .col-s-12 {width: 100%;}

  /* Headings*/
  h1 {
    @include secondaryFont();
    font-weight: 800;
    font-size: 2.1rem;
    text-align: center;
  }

  h2{
    @include secondaryFont();
    text-align: center;
    font-weight: 400;
    font-size: 2rem;
  }

  h3{
    @include secondaryFont();
    font-weight: 400;
    font-size: 1.8rem;
  }

  p{
    @include primeFont();
    color: $primary-color;
    font-weight: 300;
    font-size: 1rem;
  }

  .container {
    display: flex;
    justify-content: center;
    margin: auto;
    flex-direction: column;
  }

}
