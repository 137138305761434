@import './../Assets/mixins';

body{
  margin:0px;
  padding:0px;
  @include primeFont();
}
code{
  border: 1px solid #ccc;
  padding: 2px 4px;
  background:#eee;
  border-radius: 3px; 
}
.app{
  background: rgb(2,0,36);
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(255,175,189,1) 0%, rgba(255,195,160,1) 100%, rgba(0,212,255,1) 100%);
  margin:0px;
  padding:30px;
  min-height: 100vh;
}
.heading{
  color:$primary-color;
  font-size: $font-size * 2;
  @include primeFont();
  width: 100%;
  text-align: center;
}
.subHeading{
  @include secondaryFont();
  text-align: center;
}
.warningIcon{
  display: inline-block;
  height: 16px;
  width: 16px;
  background-image: url("#{$img-url}");
  background-size: contain;
}
//Header-------------------------------------------------------------------------------------


/*= HEADER | LARGE DEVICES
--------------------------------------------------------*/
/* header style */
header {
  text-align: center;
  background-color: $primary-background-color;
  color: $secondary-color;
  display: block;
  height: 140px;
  -moz-box-shadow: 1px 2px 3px rgba(0, 0, 0,0.5);
  -webkit-box-shadow: 1px 2px 3px rgba(0, 0, 0, .5);
  box-shadow: 1px 2px 3px rgba(0, 0, 0, .5);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
}


/*= Navigation
--------------------------------------------------------*/

.img-logo {
  margin: .5rem auto;
  width: 120px;
  height: 120px;
}

.menu-flex{
  display: flex;
  flex-direction: row;
  justify-content:space-around;
}

.topnav {
  overflow: hidden;
  font-family: 'Bebas Neue', cursive  !important;
  font-size: 1.6rem;
  margin: 2rem auto;
  padding: .5rem 0;
}

.topnav a {
  padding: 0.5rem 1rem;
  text-decoration: none;
  color: #3e484f;
}

.topnav a:hover {
  color: #216a7e;

}

.topnav a.active::after {
  font-size: 1.7rem;
  color: #216a7e;
  border-bottom: 3px solid #216a7e;
}



a.contact-link{
  line-height: 1.5;
  color: #3e484f;
  border: 2px solid #3e484f;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
}

a.contact-link-active{
  line-height: 1.5;
  color: #216a7e;
  border: 3px solid #216a7e;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
}

a.contact-link:hover {
  color: #216a7e;
  border: 2px solid #216a7e;
}

.topnav .icon {
  display: none;
}


/*= HEADER | MEDIUM/TABLET
--------------------------------------------------------*/
@media  (min-width: 678px) and (max-width: 1091px) {
  .topnav {
    margin: 2.5rem auto;
  }

  .topnav a {
    font-size: 1.5rem;
  }

  .topnav a.active {
    font-size: 1.5rem;
  }
}




/*= HEADER | SMALL/MOBILE
--------------------------------------------------------*/
@media screen and (max-width: 677px) {
  /* navigation style for small size devices */

  header {
    text-align: center;
    background-color: #fff;
    color: #282e33;
    display: block;
    height: auto;
    position: relative;
    top: 0;
  }

  /* logo responsive*/
  .img-logo {
    display: block;
    width: 100px;
    height: 100px;
  }

  .menu-flex{
    display: block;
    flex-direction: column;
  }

  .topnav {
    font-size: 1.2rem;
    margin: 1rem auto;
  }

  .topnav a{
    display: none;
  }

  .topnav a.active {
    font-size: 1.3rem;
    color: #216a7e;
    border-bottom: 2px solid #216a7e;
  }


  a.contact-link{
    line-height: 1.5;
    color: #3e484f;
    border: 1px solid #3e484f;
    padding: 0.5rem 1rem;
    border-radius: 2rem;
  }

  a.contact-link:hover {
    color: #216a7e;
    border: 1px solid #216a7e;
    font-size: 1.2rem;
  }

  .topnav a.icon {
    float: right;
    display: block;
    position: absolute;
    top: 25%;
    right: 10%;
  }


  .topnav.responsive a {
    float: none;
    display: block; 
    text-align: left;
    top: 5%;
    right: 5%;
  }

}

// Azfa-------------------------------------------------------------------------------------
.project-content{
  width: 70%;
  margin: 1.5rem auto 3rem;
  
}

.container-azfa {
  display: block;
  justify-content:center;
  text-align: justify;
  
  

  
}
.flex-container {
  display: flex;
  justify-content:space-around;
  margin: auto;
}

h1.project-title{
  font-size: 3.2rem;
}

p.project-desc{
  width: 80%;
  margin: 1rem 1rem 0 0;
  text-align: justify;
  
  
}

hr.bh-divider{
  border: 1px solid #2C91AD;
}

h2.project-subtitle{
  background-color:#2C91AD;
  color: #fff;
  padding: .2rem .5rem;
  font-size: 2.3rem;
  font-weight: 400;
  text-align: left;
  width: 50%;
}

ul.project-list{
  width: 90%;
  line-height: 2;
}

.project-subtitle2{
  background-color:#2C91AD;
  color: #fff;
  padding: .2rem .5rem;
  font-size: 2.3rem;
  font-weight: 400;
  text-align: left;
  width: 70%;
}

.project-content a{
  color: #2C91AD;
}

.img-datacenter-arch {
  width: 50%;
  margin: auto;
  
}

.img-tech{
  width: 100%;
}
.img-tech2 {
  width: 100%;
  height: 500px;
}
.mb-1{
  margin-bottom: 2%;
}
.mr-1{
  margin-right: 2%;
}
.mb-2{
  margin-bottom: 5%;
}



.negrilla {
  font-weight: bold;

}

.lista{
  scale: 0.9;
  position: relative;
  top: -15px;
  left: 25px;
  
}

.lyst {
  list-style-type: none; /* Elimina los marcadores de lista predeterminados */
  
}

.lyst li {
  padding: 10px 0; /* Agrega un padding vertical para separar los elementos de la lista */
  position: relative; /* Permite posicionar el marcador personalizado */
}

.lyst li:before {
  content: '•'; /* Define el marcador personalizado */
  position: absolute; /* Posiciona el marcador en relación al elemento de la lista */
  left: -20px; /* Mueve el marcador a la izquierda del texto */
  color: #000000; /* Cambia el color del marcador */
  font-size: 20px; /* Cambia el tamaño del marcador */
}

.lyst li:hover {
  background-color: #f6f6f6; /* Cambia el color de fondo cuando se pasa el mouse sobre un elemento de la lista */
}
@media screen and (max-width: 677px){

  .project-content{
      width: 90%;
    text-align: justify;
  }

  h1.project-title{
      font-size: 2.5rem;
  }

  p.project-desc{
      width: 100%;
      margin: 1.5rem 0;
      
  }

  h2.project-subtitle{
      padding: .2rem .5rem;
      font-size: 2rem;
      width: 90%;
    
  }

  ul.project-list{
      width: 100%;
      line-height: 2;
      margin-top: 1rem;
  }

  .img-tech{
      width: 100%;
  }
  .container-azfa{
      display: block;
  }
  .img-datacenter-arch {
      width: 100%;
      margin: auto;
      
  }
  .project-subtitle2{
    width: 100%;
  }
 

}
//Us ----------------------------------------------------------------------------

@mixin primeFont() {
  font-family: 'Roboto', sans-serif;
}

.col-6 {
  width: 50%;
  position: relative;
  top: 80px;
  left: 80px;
}

.main-content{
  margin-top: 0px;
  min-height: 600px;
}

.container {
  display: flex;
  justify-content: center;
  margin: auto;
}

.index{ width: 100%;
      background-color: #EDEEEF;
      margin: 0px;
      overflow: hidden;
      padding: 50px 0 0 0;
      min-height: 600px;
}

.contact-left{
  width: 40%;
  margin: 2.5rem auto;
}

.rw-sentence{
  font-family: 'Bebas Neue', cursive !important;
  font-weight: 200;
  font-size: 5rem;
  color: #3E484F;
  padding: 0px;
  ;
}
.padding-1{
  padding: 5%;
}

.bg-title{
  background-color: #D8D8D8;
  animation-name: bgTitle;
  animation-duration: 5s;
  padding: 5px;
  border-radius: 2px;
  animation-iteration-count: infinite;
}
.padding-1{
  padding: 5%;
}
.text-left{
  text-align: left;
}
.text-justify{
  text-align: justify;
  width: 750px;
}
@keyframes  bgTitle {
  from {background-color: #D8D8D8;}
  to {background-color: #2c91ad; color: #fff;}

}

.button_lang {
  background: none;
  border: none;
  padding: 0;
  font-size: 22px;
  cursor: pointer;
  position: relative;
  @include primeFont();
  left: 36rem;
  

  
  
}

.languageButtons {
  display: flex;
  justify-content: center;
  background-color: #EDEEEF;

  
}


.negrilla {
  font-weight: bold;
  
}

.quien{
  justify-content: center;
  text-align: justify;
  width: 550px;
  font-size: 20px;
}

.who{
  justify-content: center;
  text-align: justify;
  width: 550px;
  font-size: 20px;
}


/* ABOUT US | SMALL DEVICES
--------------------------------------------------------*/

@media screen and (max-width: 1023px) {

  /* heading style for Home Page Banner */
 h1.rw-sentence{
     font-family: 'Bebas Neue', cursive !important;
     font-weight: 200px;
     font-size: 3rem;
     color: #3E484F;
     display: flex;
     flex-direction: column;
     width: 350px;
     scale: 1;
     position: relative;
     right: 30px;
     bottom: 20px;
 }
 .bg-title {
  width: 300px;
  position: relative;
  left: 25px;
  
 }
 .text-left{
  width: 230px;
  position: relative;
  bottom: 60px;
  left: 15px;
 }
 .text-justify{
  position: relative;
  bottom: 50px;
  left: -75px;
  width: 350px;
 }
 .container{
 
  height: 350px;
 }
 .quien {
  justify-content: center;
  text-align: justify;
  width: 300px;
  font-size: 20px;
  position: relative;
  left: -60px;
  top: -60px;
}
.who {
  justify-content: center;
  text-align: justify;
  width: 300px;
  font-size: 20px;
  position: relative;
  left: -60px;
  top: -60px;
}

}

@media screen and (max-width: 1023px) {
  /* For mobiles: */
  .col-s-1 {width: 8.33%;}
  .col-s-2 {width: 16.66%;}
  .col-s-3 {width: 25%;}
  .col-s-4 {width: 33.33%;}
  .col-s-5 {width: 41.66%;}
  .col-s-6 {width: 50%;}
  .col-s-7 {width: 58.33%;}
  .col-s-8 {width: 66.66%;}
  .col-s-9 {width: 75%;}
  .col-s-10 {width: 83.33%;}
  .col-s-11 {width: 91.66%;}
  .col-s-12 {width: 100%;}

  /* Headings*/
  h1 { 
      font-family: 'Bebas Neue', cursive  !important;
      font-weight: 800;
      font-size: 2.1rem;
      text-align: center;
  }

  h2{
      font-family: 'Bebas Neue', cursive ;
      text-align: center;
      font-weight: 400;
      font-size: 2rem;
      
     
  }

  h3{
      font-family: 'Bebas Neue', cursive ;
      font-weight: 400;
      font-size: 1.8rem;
  }

  p{
      font-family: 'Roboto', sans-serif;
      color: #3E484F;
      font-weight: 300;
      font-size: 1rem;
  }

  .container {
      display:flex;
      justify-content: center;
      margin: auto;
      flex-direction: column;
      
  }
  .col-6{
      
      position: relative;
      top: -20px;
  }
  .button_lang {
      position: relative;
      left: 127px;
  }

}
// Contact---------------------------------------------------------------------------

.main-content{
  margin-top: 0px;
  min-height: 600px;
}

.container {
  display: flex;
  justify-content: center;
  margin: auto;
}


.contact-us{
  background-color: #2C91AD;
  width: 100%;
  margin: 0px;
  overflow: hidden;
  padding: 50px 0 0 0;
  min-height: 600px;
}

.contact-left{
  width: 40%;
  margin: 2.5rem auto;
}

/* heading style for Home Page Banner */
h1.contact-title{
  font-family: 'Bebas Neue', cursive;
  font-weight: 200;
  font-size: 5rem;
  color: #fff;
  padding: 0px;
}

.bg-title-contact{
  background-color: #282E33;
  animation-name: bgTitleContact;
  animation-duration: 5s;
  padding: .2rem .8rem 0 1rem;
  animation-iteration-count: infinite;
  
}

@keyframes  bgTitleContact {
from {background-color: #D8D8D8; color: #282e33;}
to {background-color: #282E33;} 

}

.contact-right{
  width: 40%;
  color: white;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  margin: 2rem auto;
}

.contact-right h2{
  font-family: 'Roboto', sans-serif;
  font-size: 8rem;
  margin: 2rem auto;
}

.contact-right h3{
  font-family: 'Roboto', sans-serif;
  font-size: 2rem;
  font-weight: 300;
  margin: 0;
}


/* CONTACT | SMALL DEVICES
--------------------------------------------------------*/
@media screen and (max-width: 677px){
  
  .contact-left{
      width: 80%;
      margin: 0 auto;
  }

  .contact-right{
      width: 90%;
      margin: 1rem auto 5rem;
  }
  
  .contact-right h2{
      font-size: 5rem;
      margin: 0 auto;
  }
  
  .contact-right h3{
      font-size: 1.6rem;
  }

  .container{
      display: block;
  }
}

// EcoRolling---------------------------------------------------------------------------------------------------------

.project-content{
  width: 70%;
  margin: 1.5rem auto 3rem;
}

.container {
    display: flex;
    justify-content:space-between;
    margin: auto;

}
.flex-container {
    display: flex;
    justify-content:space-around;
    margin: auto;
}

h1.project-title{
  font-size: 3.2rem;
}

p.project-desc{
    width: 80%;
    margin: 1rem 1rem 0 0;
    text-align: justify;
  
  
}

hr.bh-divider{
  border: 1px solid #2C91AD;
}

h2.project-subtitle{
  background-color:#2C91AD;
  color: #fff;
  padding: .2rem .5rem;
  font-size: 2.3rem;
  font-weight: 400;
  text-align: left;
  width: 50%;
}

ul.project-list{
    width: 90%;
    line-height: 2;
}


.project-content a{
  color: #2C91AD;
}

.img-datacenter-arch {
    width: 50%;
    margin: auto;
  
}

.img-tech{
    width: 100%;
    text-align: center;
    scale: 1;
  
}
.img-tech2 {
  width: 100%;
  height: auto;
  scale: 1;
}

.imgt {
    scale: 0.8;
    align-items: center;
}
.mb-1{
  margin-bottom: 2%;

}
.mr-1{
  margin-right: 2%;
}
.mb-2{
  margin-bottom: 5%;
}



.negrilla {
  font-weight: bold;

}


@media screen and (max-width: 677px){

  .project-content{
      width: 90%;
    text-align: justify;
  }

  h1.project-title{
      font-size: 2.5rem;
  }

  p.project-desc{
      width: 100%;
      margin: 1.5rem 0;
      
  }

  h2.project-subtitle{
      padding: .2rem .5rem;
      font-size: 2rem;
      width: 80%;
    
  }

  ul.project-list{
      width: 100%;
      line-height: 2;
      margin-top: 1rem;
  }

  .img-tech{
      width: 100%;
  }
  .container{
      display: block;
  }
  .img-datacenter-arch {
      width: 100%;
      margin: auto;
      
  }
  .imgt {
      width: 100%;
      align-items: center;
  }
 
}

//Success-stories----------------------------------------------------------------------------------------------------------------------------

.main-content{
  margin-top: 0px;
  min-height: 600px;
}

.projects{
  background-color: #282E33;
  margin: 0px;
  overflow: hidden;
  padding: 50px 0 0 0;
  min-height: 600px;

}
.mr-2{
  margin-right: 5%;
}


.container-s {
  display: flex;
  justify-content: center;
  margin: auto;
}
.project-card-border{
  background-color: transparent;
  border-radius: 4px;
  border: 2px solid #fff;
  width: 30%;
  height: 30%;
  padding: 1rem;
}

.project-card{
  background-color: #fff;
  color: #282E33;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  line-height: 1.3;
  min-height: 40%;
}
.project-card-title{
  min-height: 160px;
  font-size: 2.5rem;
  
}

.project-btn{
  background-color: #282E33;
  color: #fff;
  border: 0;
  border-radius: 50px;
  padding: 1rem 2rem;
  margin: 1rem auto;
  font-family: 'Bebas Neue', cursive;
  font-size: 1.8rem;
  text-align: center;
  text-decoration: none;
  width: 50%;
  position: relative;
  top: -20px;
}

.project-btn:hover {
  background-color: #2C91AD;
  color: #fff;
}


.project-btn a{
  text-decoration: none;
  color: #fff;
}

@media screen and (max-width: 677px) and (max-width: 677px)  {

  .project-card-border{
      width: 90%;
      margin: 1.5rem auto;
  }
  .container-s{
      display: block;
  }

 
}

//Footer------------------------------------------------------------------------------------------

.footer {
  background: url('../img//Footer/footer-bg.jpg')fixed;
  background-position: 100% 90%;
  width: 100%;
  height: auto;
 
}

.container-f {
  display: flex;
  justify-content: center;
  margin: auto;
 
}

.flex-col {
  display: flex;
  margin: auto;
  flex-direction: column;
}

.social-media {
  display: flex;
  justify-content: space-between;
  margin: auto;
  flex-direction: row;
  width: 40%;

}

.img-sm {
  width: 50px;
  height: auto;
}
.img-sm2 {
  width: 60px;
  height: auto;
}

.credits { 
  text-align: center;
}
